import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import ErrorBoundary from '../ErrorBoundary';
import Media from '../Media';
import Link from '../Link';
import ContentModule from '../ContentModule';

import { sidekick } from '../../utils/sidekick';
import getFirstOfArray from '../../utils/base/getFirstOfArray';

import type { CardProps as MuiCardProps } from '@mui/material/Card';
import type { CardProps } from './Card.types';
import type { LinkProps } from '../Link';

export const Card = ({
  variant,
  title,
  subtitle,
  media,
  body,
  link,
  tags,
  actions,
  loading,
  sidekickLookup,
  ...props
}: CardProps) => {
  return (
    <ErrorBoundary>
      <Root
        variant={variant}
        className={!!link ? 'has-link' : ''}
        data-testid="Card"
        {...sidekick(sidekickLookup)}
        {...(props as any)}
      >
        {!!link ? <CardLink __typename="Link" noLinkStyle {...(link as any)} /> : null}

        {media || loading ? (
          <CardMedia sx={{ display: 'flex', justifyContent: 'center' }}>
            {!loading ? (
              <Media {...sidekick(sidekickLookup?.media)} {...getFirstOfArray(media)} testId="Card-media" />
            ) : (
              <Skeleton>
                <Media {...sidekick(sidekickLookup?.media)} {...getFirstOfArray(media)} testId="Card-media" />
              </Skeleton>
            )}
          </CardMedia>
        ) : null}

        {tags?.length ? <CardTags>{tags?.map((tag) => <CardTag key={tag.id} {...tag} />)}</CardTags> : null}

        {!loading && (title || subtitle || body || actions) ? (
          <CardContent>
            {title ? (
              <Typography {...sidekick(sidekickLookup?.title)} variant="h3" component="h3" data-testid="Card-title">
                {title}
              </Typography>
            ) : null}

            {subtitle ? (
              <Typography
                {...sidekick(sidekickLookup?.subtitle)}
                variant="h4"
                component="h4"
                data-testid="Card-subtitle"
              >
                {subtitle}
              </Typography>
            ) : null}

            {body ? (
              <ContentModule
                __typename="Text"
                variant="card"
                sidekickLookup={sidekickLookup?.body}
                body={body}
                data-testid="Card-body"
              />
            ) : null}

            {actions?.length ? (
              <CardActions {...sidekick(sidekickLookup?.actions)} data-testid="Card-actions">
                {actions?.map((link) => <Link key={link.id} {...link} />)}
              </CardActions>
            ) : null}
          </CardContent>
        ) : null}

        {loading ? (
          <CardContent>
            <Typography variant="h3" component="h3">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="h4" component="h4">
              <Skeleton width="100%" />
              <br />
              <Skeleton width="100%" />
            </Typography>
            <Skeleton>
              {body ? (
                <ContentModule
                  __typename="Text"
                  variant="card"
                  sidekickLookup={sidekickLookup?.body}
                  body={body}
                  data-testid="Card-body"
                />
              ) : null}
            </Skeleton>
            <CardActions>
              <Skeleton width={50} />
            </CardActions>
          </CardContent>
        ) : null}
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(MuiCard, {
  name: 'Card',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<MuiCardProps & {}>`
  position: relative;
  &.has-link {
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.65;
    }
  }
`;

const CardTags = styled(Box, {
  name: 'Card',
  slot: 'Tags',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.cardTags],
})<{}>`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

const CardTag = ({ href, text }: LinkProps) =>
  !href || href === '#' ? (
    <Chip label={text} />
  ) : (
    <CardTagRoot __typename="Link" href={href}>
      <Chip label={text} clickable />
    </CardTagRoot>
  );

const CardTagRoot = styled(Link, { name: 'Card', slot: 'TagRoot' })``;

const CardLink = styled(Link, {
  name: 'Card',
  slot: 'CardLink',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.cardLink],
})<LinkProps & {}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export default Card;
